const styles = {
  global: {
    "@font-face": {
      // fontFamily: 'Noto Sans Mono',
      // src: "url('/assets/fonts/NotoSansMono-Regular.ttf') format('truetype')"
    },
    html: {
      "@font-face": {
        // fontFamily: 'Noto Sans Mono',
        // src: "url('/assets/fonts/NotoSansMono-Regular.ttf') format('truetype')"
      },
    },
    body: {
      minW: "100%",
      minH: "100vh",
      // fontFamily: 'Noto Sans Mono',
    },
    ".icon": {
      width: "12px",
      height: "12px",
    },
    ".menu-item": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#fff",
      width: "145px",
      height: "36px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "145px",
      cursor: "pointer",
      "&:hover": {
        background:
          "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
        WebkitBackgroundClip: "text",
        color: "transparent",
      },
      "@media screen and (max-width: 820px)": {
        fontSize: "18px",
        background: "rgba(30, 30, 30, 0.30)",
        border: "1px solid var(--Linear, #F8C9F4)",
        borderRadius: "25px",
        boxShadow: "0px 12px 50px 0px rgba(137, 82, 255, 0.20)",
        backdropFilter: "blur(15px)",
        width: "100%",
        height: "51px",
      },
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: 0,
        transform: "translateY(20px)",
      },
      to: {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
    ".fadeInUp": {
      // animation: "fadeInUp 3s ease forwards",
    },
    ".hstack": {
      transition: "all 0.3s ease",

      "&:hover": {
        transform: "scale(1.01)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      },
    },
    ".hstackImg": {
      transition: "all 0.3s ease",

      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },
};

export default styles;
