import { createBrowserRouter, Navigate } from 'react-router-dom'
import Layout from '../layout'
import Notfound from '../pages/404'
import Home from '../pages/home'
const router = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      // {
      //   path: '/',
      //   element: <Navigate to="/Home" />
      // }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/404" />
  },
  {
    path: '/404',
    element: <Notfound />
  }
]

export default createBrowserRouter(router)
