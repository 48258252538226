import { Box, Flex, Button, Image, Text, VStack, HStack } from '@chakra-ui/react'
import mobileVideoImage from '../assets/images/mobile-video.png'
import mobileVideoH5Image from '../assets/images/mobile-video-h5.png'
import androidImage from '../assets/images/android.svg'
import iphoneImage from '../assets/images/iphone.svg'
import bannerBgImage from '../assets/images/banner-bg.png'
import spaceImage from '../assets/images/space.png'
import point1Image from '../assets/images/point1.svg'
import point2Image from '../assets/images/point2.svg'
import point3Image from '../assets/images/point3.svg'
import point4Image from '../assets/images/point4.svg'
import point5Image from '../assets/images/point5.svg'
import roadImage from '../assets/images/road.png'
import roadH5Image from '../assets/images/road-h5.png'
import roadLineImage from '../assets/images/road-line.png'
import tonImage from '../assets/images/ton.svg'
import binanceImage from '../assets/images/binance.svg'
import metamaskImage from '../assets/images/metamask.svg'
import bitkeepImage from '../assets/images/bitkeep.svg'
import galaxyImage from '../assets/images/galaxy.svg'
import elementImage from '../assets/images/element.svg'
import taskonImage from '../assets/images/taskon.svg'
import jinsecaijingImage from '../assets/images/jinsecaijing.svg'
import coin98Image from '../assets/images/coin98.svg'
import footerLineImage from '../assets/images/footer-line.png'
import youtubeImage from '../assets/images/youtube.svg'
import tiktokImage from '../assets/images/tiktok.svg'
import telegramImage from '../assets/images/telegram.svg'
import twitterImage from '../assets/images/twitter.svg'
// import discordImage from '../assets/images/discord.svg'
import roundImage from '../assets/images/round.svg'
import bannerBgH5Image from '../assets/images/banner-bg-h5.png'
import titleBgImage from '../assets/images/title-bg.png'
import leftBgImage from '../assets/images/left-bg.png'
import rightBgImage from '../assets/images/right-bg.png'
import { Link as ScrollLink } from 'react-scroll'
import { useToast } from '@chakra-ui/react'

export default function Home() {
    const toast = useToast();

    const handleTwitterClick = () => {
        window.open('https://x.com/Xspace2024', '_blank');
    };
    const handleYoutubeClick = () => {
        window.open('https://www.youtube.com/@X-Spaces', '_blank');
    };
    const handleTiktokClick = () => {
        window.open('https://www.tiktok.com/@xs20240601', '_blank');
    };
    const handleTelegramClick = () => {
        window.open('https://t.me/XSpaceVS', '_blank');
    };
    const handleDocsClick = () => {
        window.open('https://x-space.gitbook.io/x-space-doc_en', '_blank');
    };

    const handleClick = () => {
        toast({
            title: "Coming soon",
            status: "info",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box
            w="100%"
            mt={'160px'}
            sx={{
                backgroundColor: '#0A050B',
                '@media screen and (max-width: 1000px)': {
                    mt: '56px',
                    w: '100%',
                    p: '0',
                }
            }}
        >
            <HStack
                w="100%"
                gap="124px"
                justifyContent="space-between"
                alignItems="flex-start"
                mt={'78px'}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: '0',
                        width: '1345px',
                        height: '766px',
                        background: `url(${bannerBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 1,
                        marginRight: '-100px',
                        '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                            marginRight: '-300px',
                        },
                        '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                            marginRight: '-300px',
                        },
                    },
                    '@media screen and (min-width: 1001px) and (max-width: 1919px)': {
                        gap: '80px',
                    },
                    '@media screen and (max-width: 1000px)': {
                        '::before': {
                            display: 'none',
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '32px',
                        gap: '30px',
                    }
                }}>
                <Box
                    position="relative"
                    zIndex={2}
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginTop: '0',
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            alignItems: 'center',
                        }
                    }}
                >
                    <VStack gap="16px" alignItems="flex-start" w={'727px'}
                        sx={{
                            '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                                width: '627px',
                            },
                            '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                                width: '627px',
                            },
                            '@media screen and (max-width: 1000px)': {
                                w: '270px',
                                gap: '0',
                                alignItems: 'center',
                                textAlign: 'center',
                            }
                        }}
                    >
                        <Text fontSize={'70px'} fontWeight={400}
                            sx={{
                                '@media screen and (min-width: 1100px) and (max-width: 1365px)': {
                                    fontSize: '56px',
                                },
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '26px',
                                    fontWeight: 400,
                                }
                            }}
                        >Interact, Create, Earn</Text>
                        <Text fontSize={'70px'} fontWeight={400}
                            sx={{
                                '@media screen and (min-width: 1100px) and (max-width: 1365px)': {
                                    fontSize: '56px',
                                },
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '26px',
                                    fontWeight: 400,
                                }
                            }}
                        >Your SocialFi Journey Begins Here</Text>
                    </VStack>
                    <HStack gap="44px" mt={'140px'}
                        sx={{
                            width: '727px',
                            '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                                width: '627px',
                            },
                            '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                                width: '627px',
                            },
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                flexDirection: 'column-reverse',
                                gap: '13px',
                                mt: '13px',
                            }
                        }}
                    >
                        <Button
                            className='hstackImg'
                            background={'var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))'}
                            boxShadow={'0px 4px 4px 0px #2B59D2'}
                            borderRadius={'100px'}
                            w={'294px'}
                            h={'81px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '140px',
                                    h: '35px',
                                }
                            }}
                            onClick={() => window.open('https://mapi.xspace.land/h5/download', '_blank')}
                        >
                            <Image src={androidImage} w={'37px'} h={'43.463px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '15.693px',
                                        height: '18.435px',
                                    }
                                }}
                            />
                            <Text fontSize={'40px'} fontWeight={400} color={'#fff'} ml={'20px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                    }
                                }}
                            >Android</Text>
                        </Button>
                        <Button
                            className='hstackImg'
                            background={'linear-gradient(90deg, #FD36D4 0%, #B961FF 53.5%, #01F8FF 100%)'}
                            boxShadow={'0px 4px 4px 0px #3552D0'}
                            borderRadius={'100px'}
                            w={'294px'}
                            h={'81px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '140px',
                                    h: '35px',
                                }
                            }}
                            onClick={() => window.open('https://fir.xcxwo.com/vqjeyzhr', '_blank')}
                        >
                            <Image src={iphoneImage} w={'37.264px'} h={'42.476px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '16.067px',
                                        height: '18.315px',
                                    }
                                }}
                            />
                            <Text fontSize={'40px'} fontWeight={400} color={'#fff'} ml={'20px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',

                                    }
                                }}
                            >IPhone</Text>
                        </Button>
                    </HStack>
                </Box>
                <Box
                    position="relative"
                    zIndex={2}
                    width={'747px'}
                    sx={{
                        '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                            width: '547px',
                        },
                        '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                            width: '547px',
                        },
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            pr: '32px',
                            '::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-10px',
                                right: '0',
                                width: '100%',
                                height: '210px',
                                background: `url(${bannerBgH5Image}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                zIndex: -1,
                                m: '0',
                            },
                        }
                    }}
                >
                    <Image src={mobileVideoImage} w={'515px'} h={'581px'}
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                display: 'none'
                            }
                        }} />
                    <Image
                        src={mobileVideoH5Image}
                        alt="logo"
                        display="none"
                        w="263px"
                        h="297px"
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                display: 'block'
                            }
                        }}
                    />
                </Box>
            </HStack >
            <Flex
                id='about'
                sx={{
                    w: '100%',
                    position: 'relative',
                    zIndex: 3,
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-270px',
                        width: '604px',
                        height: '604px',
                        background: `url(${leftBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 2,
                    },
                }}
            >
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >X-Space is dedicated to creating a decentralized social network that enables users to earn economic rewards through interactions and content creation. Utilizing blockchain technology, we ensure data privacy and give users complete control over their personal information. Through DAO, we achieve transparent community governance, allowing users to participate in major decisions. The platform provides fair monetization opportunities for content creators, enabling earnings through tokens and NFT transactions. Additionally, we promote the "Social to Earn (S2E)" model, where users receive token rewards for social interactions. X-Space aims to become a secure, transparent, and user-driven comprehensive ecosystem, maximizing user value.</Text>
            </Flex>
            <Box id='features'>
                <Flex
                    sx={{
                        background: `url(${spaceImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        width: '494.025px',
                        height: '173.45px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '65px auto 0',
                        '@media screen and (max-width: 1000px)': {
                            w: '181.975px',
                            h: '67px',
                            margin: '20px auto 0',
                        }
                    }}
                >
                    <Text
                        sx={{
                            fontSize: '46px',
                            fontWeight: 400,
                            color: '#fff',
                            pt: '30px',
                            '@media screen and (max-width: 1000px)': {
                                fontSize: '14px',
                            }
                        }}
                    >Features</Text>
                </Flex>
                <VStack
                    sx={{
                        margin: '0 auto',
                        gap: '50px',
                        position: 'relative',
                        overflow: 'hidden',
                        p: '135px 0 95px 0',
                        '::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            right: '-302px',
                            width: '604px',
                            height: '604px',
                            background: `url(${rightBgImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                            zIndex: 1,
                        },
                        '@media screen and (max-width: 1000px)': {
                            p: '10px 14px 35px 14px',
                            gap: '20px',
                        }
                    }}
                >
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack gap={'20px'} w={'214px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point1Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '35px',
                                        h: '35px',
                                    }
                                }}
                            />
                            <Text fontSize={'28px'} fontWeight={400} lineHeight={'42px'} textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >Short Video and Live Streaming</Text>
                        </VStack>
                        <VStack gap={'15px'} width={'721px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '100%',
                                }
                            }}
                        >
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Short Video：
                                </Text>
                                Users can easily shoot and upload short videos to share every wonderful moment of life. The platform supports a variety of editing tools and filter effects to help users create high-quality video content.
                            </Flex>
                            <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Live Streaming：
                                </Text>
                                Users can conduct real-time live broadcasts and interact with their audience. During live streams, viewers can participate by sending virtual gifts and real-time comments, enhancing the interactive experience.
                            </Flex>
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack gap={'20px'} w={'214px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}>
                            <Image src={point2Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '32.366px',
                                        h: '39.382px',
                                    }
                                }}
                            />
                            <Text fontSize={'28px'} fontWeight={400} lineHeight={'42px'} textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >Blockchain Technology</Text>
                        </VStack>
                        <VStack gap={'15px'} width={'721px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '100%',
                                }
                            }}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Data Privacy and Security：
                                </Text>
                                Utilizing blockchain's distributed ledger technology ensures the security and privacy of all user data. All data is encrypted and stored on the blockchain, giving users complete control over their information, eliminating concerns about data breaches or misuse.
                            </Flex>
                            <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Smart Contracts：
                                </Text>
                                Smart contracts automatically enforce platform rules and reward distribution, ensuring each user's rights and the transparency of platform operations, automating transactions between users, reducing human intervention and errors.
                            </Flex>
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack
                            gap={'20px'}
                            w={'214px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point3Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '39.463px',
                                        h: '35.453px',
                                    }
                                }}
                            />
                            <Text
                                fontSize={'28px'}
                                fontWeight={400}
                                lineHeight={'42px'}
                                textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >DAO Governance</Text>
                        </VStack>
                        <VStack gap={'30px'} width={'721px'}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Transparent Governance：
                                </Text>
                                The platform is governed by a DAO, where all major decisions are made by community member votes. Users can participate in voting by holding platform tokens, directly influencing the platform's development direction and operational strategies.
                            </Flex>
                            <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Incentive Mechanism：
                                </Text>
                                By actively participating in community governance and proposals, users can earn additional token rewards, encouraging them to contribute to the platform's growth and development.
                            </Flex>
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack
                            gap={'20px'}
                            w={'214px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point4Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '44px',
                                        h: '44px',
                                    }
                                }}
                            />
                            <Text
                                fontSize={'28px'}
                                fontWeight={400}
                                lineHeight={'42px'}
                                textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >Token Economy Incentives</Text>
                        </VStack>
                        <VStack gap={'30px'} width={'721px'}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Content Monetization：
                                </Text>
                                Content creators can earn revenue through token and NFT transactions within the platform. Users can mint high-quality video content as NFTs and buy or sell them within the platform to earn additional income.
                            </Flex>
                            <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    S2E Model：
                                </Text>
                                Every interaction and content creation on the platform can earn users token rewards. By watching videos, liking, commenting, and sharing, users receive corresponding tokens, increasing user engagement and activity.
                            </Flex>
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack
                            gap={'20px'}
                            w={'214px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point5Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '53.498px',
                                        h: '39.497px',
                                    }
                                }}
                            />
                            <Text
                                fontSize={'28px'}
                                fontWeight={400}
                                lineHeight={'42px'}
                                textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >NFT Marketplace</Text>
                        </VStack>
                        <VStack gap={'30px'} width={'721px'}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Content Minting and Trading：
                                </Text>
                                Users can mint their high-quality content as NFTs and trade them within the platform. The NFT marketplace provides content creators with additional monetization avenues, allowing them to earn extra income by selling NFTs.
                            </Flex>
                            <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Unique NFT Gameplay：
                                </Text>
                                The platform will introduce various unique NFT features, such as limited edition NFTs and exclusive NFT gifts, enhancing user interaction and engagement.
                            </Flex>
                        </VStack>
                    </HStack>
                </VStack>
            </Box>
            <Box
                sx={{
                    // mt: '95px',
                    // '@media screen and (max-width: 1000px)': {
                    //     mt: '35px',
                    // }
                }}
            >
                <Flex
                    sx={{
                        background: `url(${roadImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        width: '100%',
                        height: '357px',
                        alignItems: 'center',
                        '@media screen and (max-width: 1000px)': {
                            background: `url(${roadH5Image}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '100%',
                            height: '67px',
                            alignItems: 'center',
                        },
                    }}
                >
                    <Flex
                        sx={{
                            fontSize: '46px',
                            fontWeight: 400,
                            background: `url(${spaceImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '494.025px',
                            height: '173.45px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto',
                            '@media screen and (max-width: 1000px)': {
                                w: '195.975px',
                                h: '73.806px',
                                m: '28px auto 0'
                            }
                        }}
                    >
                        <Text
                            sx={{
                                fontSize: '46px',
                                fontWeight: 400,
                                color: '#fff',
                                pt: '30px',
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '14px',
                                    pt: '10px',
                                }
                            }}
                        >Roadmap</Text>
                    </Flex>
                </Flex>
                <Box
                    sx={{
                        background: `url(${roadLineImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        width: '1337px',
                        height: '796.698px;',
                        position: 'relative',
                        m: '100px auto 0',
                        '@media screen and (max-width: 1000px)': {
                            display: 'none',
                        }
                    }}
                >
                    <VStack gap={'0px'}
                        sx={{
                            alignItems: 'flex-start',
                            position: 'absolute',
                            top: '-32px',
                            left: '116px',
                        }}
                    >
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Complete the development of core platform features, including short video posting, live streaming, blockchain integration, smart contracts, and decentralized storage (IPFS).</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Initiate social media marketing and content creation, and collaborate with renowned KOLs for early promotion. Promote within blockchain and cryptocurrency communities to attract the first batch of seed users.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Launch an invitation reward program to encourage users to invite friends to register.</Text>
                        </Flex>
                    </VStack>
                    <VStack gap={'0px'}
                        sx={{
                            alignItems: 'flex-start',
                            position: 'absolute',
                            top: '145px',
                            left: '316px',
                        }}
                    >
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Develop and launch NFT minting and trading features, providing more monetization avenues for content creators.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Participate in blockchain and tech industry exhibitions and conferences to showcase platform technology and application scenarios.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Introduce the DAO feature, allowing users to participate in platform governance and community building.</Text>
                        </Flex>
                    </VStack>
                    <VStack gap={'0px'}
                        sx={{
                            alignItems: 'flex-start',
                            position: 'absolute',
                            top: '280px',
                            left: '440px',
                        }}
                    >
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Drive platform internationalization, conducting localized marketing activities and community building to attract global users.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Explore partnerships with third-party decentralized applications and services to expand the platform ecosystem.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Develop more innovative features, such as VR live streaming and AR interactions, to enhance user experience.</Text>
                        </Flex>
                    </VStack>
                    <VStack gap={'0px'}
                        sx={{
                            alignItems: 'flex-start',
                            position: 'absolute',
                            top: '410px',
                            right: '0',
                            width: '643px',
                        }}
                    >
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Launch an open API to support developers in creating and integrating more features and services on the platform.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Regularly assess and adjust market promotion and commercialization strategies to maintain platform growth and profitability.</Text>
                        </Flex>
                        <Flex alignItems={'flex-start'}>
                            <Image src={roundImage} w={'15px'} h={'15px'} m={'5px 12px 0 0'} />
                            <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>Collaborate with academic institutions and research organizations to advance research and innovation in decentralized social networks.</Text>
                        </Flex>
                    </VStack>
                </Box>
                <Box
                    sx={{
                        display: 'none',
                        '@media screen and (max-width: 1000px)': {
                            display: 'block',
                        }
                    }}
                >
                    <VStack
                        gap={'20px'}
                    >
                        <Text
                            sx={{
                                background: `url(${titleBgImage}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                width: '83px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '20px',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >Jun 2024</Text>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Complete the development of core platform features, including short video posting, live streaming, blockchain integration, smart contracts, and decentralized storage (IPFS).</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Initiate social media marketing and content creation, and collaborate with renowned KOLs for early promotion. Promote within blockchain and cryptocurrency communities to attract the first batch of seed users.</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Launch an invitation reward program to encourage users to invite friends to register.</Text>
                        </Flex>
                    </VStack>
                    <VStack
                        gap={'20px'}
                    >
                        <Text
                            sx={{
                                background: `url(${titleBgImage}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                width: '118px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '20px',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >Sep 2024</Text>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Develop and launch NFT minting and trading features, providing more monetization avenues for content creators. </Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Participate in blockchain and tech industry exhibitions and conferences to showcase platform technology and application scenarios.</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Introduce the DAO feature, allowing users to participate in platform governance and community building.</Text>
                        </Flex>
                    </VStack>
                    <VStack
                        gap={'20px'}
                    >
                        <Text
                            sx={{
                                background: `url(${titleBgImage}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                width: '168px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '20px',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >Oct 2024 - Feb 2025</Text>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Drive platform internationalization, conducting localized marketing activities and community building to attract global users.</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Explore partnerships with third-party decentralized applications and services to expand the platform ecosystem.</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Develop more innovative features, such as VR live streaming and AR interactions, to enhance user experience.</Text>
                        </Flex>
                    </VStack>
                    <VStack
                        gap={'20px'}
                    >
                        <Text
                            sx={{
                                background: `url(${titleBgImage}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                width: '168px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '20px',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >Mar 2025 - Mar 2027</Text>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Launch an open API to support developers in creating and integrating more features and services on the platform.</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Regularly assess and adjust market promotion and commercialization strategies to maintain platform growth and profitability.</Text>
                        </Flex>
                        <Flex>
                            <Image src={roundImage} w={'10px'} h={'10px'} m={'5px 8px 0 0'} />
                            <Text
                                fontSize={'14px'}
                                fontWeight={400}
                                w={'300px'}
                            >Collaborate with academic institutions and research organizations to advance research and innovation in decentralized social networks.</Text>
                        </Flex>
                    </VStack>
                </Box>
            </Box>
            <Box>
                <Flex
                    sx={{
                        width: '100%',
                        mt: '135px',
                        '@media screen and (max-width: 1000px)': {
                            mt: '14px',
                        }
                    }}
                >
                    <Flex
                        sx={{
                            fontSize: '46px',
                            fontWeight: 400,
                            background: `url(${spaceImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '494.025px',
                            height: '173.45px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto',
                            '@media screen and (max-width: 1000px)': {
                                width: '195.975px',
                                height: '68.806px',
                            }
                        }}
                    >
                        <Text
                            sx={{
                                fontSize: '46px',
                                fontWeight: 400,
                                color: '#fff',
                                pt: '30px',
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '14px',
                                }
                            }}
                        >Partners</Text>
                    </Flex>
                </Flex>
                <Box
                    sx={{
                        m: '80px 0 315px 0',
                        '@media screen and (max-width: 1000px)': {
                            m: '20px 0 0 0',
                        }
                    }}
                >
                    <VStack gap={'100px'}
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                gap: '20px',
                                p: '0 30px',
                            }
                        }}
                    >
                        <HStack w={'1337px'} justifyContent={'space-between'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    width: '100%',
                                }
                            }}
                        >
                            <Image src={tonImage} w={'245px'} h={'99px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '60px',
                                        height: '24px',
                                    }
                                }}
                            />
                            <Image src={binanceImage} w={'414px'} h={'82px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '98.459px',
                                        height: '19px',
                                    }
                                }}
                            />
                            <Image src={metamaskImage} w={'400.115px'} h={'77.193px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '96px',
                                        height: '18px',
                                    }
                                }}
                            />
                        </HStack>
                        <HStack w={'1337px'} justifyContent={'space-between'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    width: '100%',
                                }
                            }}
                        >
                            <Image src={bitkeepImage} w={'319.791px'} h={'105.68px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '76px',
                                        height: '25px',
                                    }
                                }}
                            />
                            <Image src={galaxyImage} w={'384.972px;'} h={'97.913px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '92px',
                                        height: '23px',
                                    }
                                }}
                            />
                            <Image src={elementImage} w={'413.627px;'} h={'76.262px;'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '112px',
                                        height: '20px',
                                    }
                                }}
                            />
                        </HStack>
                        <HStack w={'1337px'} justifyContent={'space-between'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    width: '100%',
                                }
                            }}
                        >
                            <Image src={taskonImage} w={'320px'} h={'91px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '77px',
                                        height: '22px',
                                    }
                                }}
                            />
                            <Image src={jinsecaijingImage} w={'353px'} h={'87px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '85px',
                                        height: '21px',
                                    }
                                }}
                            />
                            <Image src={coin98Image} w={'369px'} h={'94px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '88px',
                                        height: '23px',
                                    }
                                }}
                            />
                        </HStack>
                    </VStack>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '3px',
                        background: `url(${footerLineImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                    },
                    '@media screen and (max-width: 1000px)': {
                        display: 'none',
                    }
                }}
            >
                <HStack
                    sx={{
                        m: '0 auto',
                        width: '1337px',
                        p: '80px 0',
                        justifyContent: 'space-between',
                    }}
                >
                    <VStack gap={'30px'}>
                        <ScrollLink
                            to="about"
                            smooth={true}
                            duration={500}
                            offset={-200}
                            className='menu-item'
                        >
                            <Text fontSize="18px" fontWeight="400" color="#fff">About</Text>
                        </ScrollLink>
                        <ScrollLink
                            to="features"
                            smooth={true}
                            duration={500}
                            offset={-180}
                            className='menu-item'
                        >
                            <Text fontSize="18px" fontWeight="400" color="#fff">Features</Text>
                        </ScrollLink>
                    </VStack>
                    <VStack gap={'30px'}>
                        <Text fontSize="18px" fontWeight="400" color="#fff" cursor={'pointer'} onClick={handleClick}>Staking</Text>
                        <Text fontSize="18px" fontWeight="400" color="#fff" cursor={'pointer'} onClick={handleClick}>Governance</Text>
                    </VStack>
                    <VStack gap={'30px'}>
                        <Text fontSize="18px" fontWeight="400" color="#fff" cursor={'pointer'} onClick={handleDocsClick}>Docs</Text>
                        <Text fontSize="18px" fontWeight="400" color="#fff">PressKit</Text>
                    </VStack>
                    <VStack gap={'30px'} alignItems={'flex-start'}>
                        <Text fontSize="18px" fontWeight="400" color="#fff">Communities</Text>
                        <HStack gap={'20px'}>
                            <Image src={youtubeImage} w={'79px'} h={'18px'} sx={{ cursor: 'pointer' }} onClick={handleYoutubeClick} />
                            <Image src={tiktokImage} w={'80px'} h={'23px'} sx={{ cursor: 'pointer' }} onClick={handleTiktokClick} />
                            <Image src={telegramImage} w={'102px'} h={'28px'} sx={{ cursor: 'pointer' }} onClick={handleTelegramClick} />
                            <Image src={twitterImage} w={'87.537px'} h={'19.249px'} sx={{ cursor: 'pointer' }} onClick={handleTwitterClick} />
                            {/* <Image src={discordImage} w={'92px'} h={'18px'} /> */}
                        </HStack>
                    </VStack>
                </HStack>
                <Text fontSize="20px" fontWeight="400" color="#fff" textAlign={'center'} mb={'80px'}>@2024 X-Space All rights reserved.</Text>
            </Box>
            <Box
                sx={{
                    display: 'none',
                    '@media screen and (max-width: 1000px)': {
                        display: 'block',
                    }
                }}
            >
                <Flex
                    sx={{

                        background: `url(${spaceImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        width: '195.975px;',
                        height: '68.806px;',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '35px auto 20px',
                    }}
                >
                    <Text
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#fff',
                            pt: '10px',
                        }}
                    >Communities</Text>
                </Flex>
                <HStack
                    gap={'0'}
                    justifyContent={'space-between'}
                    sx={{
                        position: 'relative',
                        m: '0 15px',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: '-10px',
                            left: '0',
                            width: '100%',
                            height: '1px',
                            background: `url(${footerLineImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                        },
                    }}
                >
                    <Image src={youtubeImage} w={'52px'} h={'11px'} onClick={handleYoutubeClick} />
                    <Image src={tiktokImage} w={'53px'} h={'16px'} onClick={handleTiktokClick} />
                    <Image src={telegramImage} w={'69px'} h={'19px'} onClick={handleTelegramClick} />
                    <Image src={twitterImage} w={'61px'} h={'13px'} onClick={handleTwitterClick} />
                    {/* <Image src={discordImage} w={'62px'} h={'13px'} /> */}
                </HStack>
                <Text
                    sx={{
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#fff',
                        textAlign: 'center',
                        m: '20px 0 60px 0',
                    }}
                >@2024 X-Space All rights reserved.</Text>
            </Box>
        </Box >
    )
}