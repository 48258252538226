import { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Box, Flex, Center, Image, VStack } from '@chakra-ui/react'
import logoImage from '../assets/images/logo.svg'
import h5logoImage from '../assets/images/logo_h5.svg'
import WalletButton from '../components/WalletButton'
import { Link as ScrollLink } from 'react-scroll'
import { useToast } from '@chakra-ui/react'
import NavMenuList from '../components/NavMenu'
import menuImage from '../assets/images/menu.svg'
import menuCloseImage from '../assets/images/close.svg'
import youtubeImage from '../assets/images/youtube.svg'
import tiktokImage from '../assets/images/tiktok.svg'
import telegramImage from '../assets/images/telegram.svg'
import twitterImage from '../assets/images/twitter.svg'
// import discordImage from '../assets/images/discord.svg'

export default function NavHeader() {
  const [isOpenH5Menu, setIsOpenH5Menu] = useState(false)

  const stopPropagation = (e: any) => {
    e.nativeEvent.stopImmediatePropagation()
  }

  const onShow = (e: any) => {
    stopPropagation(e)
    setIsOpenH5Menu(!isOpenH5Menu)
  }

  useEffect(() => {
    document.addEventListener('click', () => {
      setIsOpenH5Menu(false)
    })
  }, [])

  const handleTwitterClick = () => {
    window.open('https://x.com/Xspace2024', '_blank');
  };
  const handleYoutubeClick = () => {
    window.open('https://www.youtube.com/@X-Spaces', '_blank');
  };
  const handleTiktokClick = () => {
    window.open('https://www.tiktok.com/@xs20240601', '_blank');
  };
  const handleTelegramClick = () => {
    window.open('https://t.me/XSpaceVS', '_blank');
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Flex
      w="100%"
      h="160px"
      alignItems="center"
      p="0px 90px 0 116px"
      position="fixed"
      left="0px"
      top="0px"
      zIndex="1000"
      justifyContent={"space-between"}
      borderBottom={'1px solid #484848'}
      background={'rgba(10, 5, 5, 0.6)'}
      sx={{
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        '@media screen and (min-width: 1280px) and (max-width: 1600px)': {
          p: '0px 30px',
        },
        '@media screen and (max-width: 1200px)': {
          p: '0px 16px',
        },
        '@media screen and (max-width: 820px)': {
          p: '20px 20px 20px 20px',
          h: '76px',
          border: 'none',
        }
      }}
    >
      <Flex
        align="center"
        minW="220px"
        sx={{
          '@media screen and (max-width: 1000px)': {
            w: 'unset',
            minW: '120px'
          },
          '@media screen and (max-width: 820px)': {
            w: 'unset',
            minW: '86px'
          }
        }}
      >
        <Link to="/">
          <Box
            onClick={scrollToTop}
            sx={{
              '@media screen and (max-width: 1000px)': {
                w: 'unset'
              }
            }}
          >
            <Image
              src={logoImage}
              alt="logo"
              w="220px"
              h="59px"
              sx={{
                '@media screen and (max-width: 1000px)': {
                  display: 'none'
                }
              }}
            />
            <Image
              src={h5logoImage}
              alt="logo"
              display="none"
              w="133px"
              h="36px"
              sx={{
                '@media screen and (max-width: 1000px)': {
                  display: 'block'
                }
              }}
            />
          </Box>
        </Link>
      </Flex>
      <Flex
        flex="1"
        justifyContent="center"
        sx={{
          '@media screen and (max-width: 820px)': {
            display: 'none'
          }
        }}
      >
        <NavMenu setIsOpenH5Menu={setIsOpenH5Menu} />
      </Flex>
      <Flex
        alignItems="center"
        justify="flex-end"
        sx={{
          '@media screen and (max-width: 1000px)': {
            w: 'unset'
          }
        }}
      >
        <WalletButton />
        {/* <SwitchTheme /> */}
        <Box
          w="14px"
          h="12px"
          alignItems="center"
          justifyContent="center"
          display="none"
          onClick={onShow}
          _hover={{
            svg: {
              fill: 'var(--chakra-colors-kurama-primary)'
            }
          }}
          sx={{
            '@media screen and (max-width: 820px)': {
              display: 'block',
              ml: '15px'
            }
          }}
        >
          <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
            <Image
              src={isOpenH5Menu ? menuCloseImage : menuImage}
              alt="menu"
              w={'15px'}
              h={'15px'} />
          </Flex>
        </Box>
        <Box
          display="none"
          position="absolute"
          h="100vh"
          right="0"
          top="76px"
          w="100%"
          _dark={{
            bg: '#0A050B'
          }}
          _light={{
            bg: 'rgba(240,240,240,0.5)'
          }}
          backdropFilter="blur(20px)"
          sx={{
            '@media screen and (max-width: 820px)': {
              display: isOpenH5Menu ? 'block' : 'none'
            }
          }}
        >
          <VStack h="100%" w={'100%'}>
            <Flex align="center" w={'100%'} mt={'30px'}>
              <NavMenu setIsOpenH5Menu={setIsOpenH5Menu} />
            </Flex>
            <Box>
              <Flex flexWrap={'wrap'} mt={'40px'}>
                <Image src={youtubeImage} w={'80.75px'} h={'17.082px'} m={'15px 15px'} onClick={handleYoutubeClick} />
                <Image src={tiktokImage} w={'78.346px'} h={'23.652px'} m={'15px 15px'} onClick={handleTiktokClick} />
                <Image src={telegramImage} w={'90.665px'} h={'24.966px'} m={'15px 15px'} onClick={handleTelegramClick} />
                <Image src={twitterImage} w={'92.484px'} h={'19.71px'} m={'15px 15px'} onClick={handleTwitterClick} />
                {/* <Image src={discordImage} w={'94px'} h={'19.71px'} m={'15px 30px'} /> */}
              </Flex>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  )
}

interface NavMenuProps {
  setIsOpenH5Menu: (isOpen: boolean) => void;
}


function NavMenu({ setIsOpenH5Menu }: NavMenuProps) {
  const toast = useToast();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // 阻止默认行为
    toast({
      title: "Coming soon",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDocsClick = (e: React.MouseEvent) => {
    e.preventDefault(); // 阻止默认行为
    window.open('https://x-space.gitbook.io/x-space-doc_en', '_blank');
  };

  const handleMenuItemClick = () => {
    setIsOpenH5Menu(false);
  };

  const statsList: any = [
    {
      text: 'Twitter',
      type: '',
      link: 'https://x.com/Xspace2024',
    },
    {
      text: 'Telegram',
      type: '',
      link: 'https://t.me/XSpaceVS',
    },
    // {
    //   text: 'Discord',
    //   type: '',
    //   link: '',
    // },
    {
      text: 'Tiktok',
      type: '',
      link: 'https://www.tiktok.com/@xs20240601',
    },
    {
      text: 'Youtube',
      type: '',
      link: 'https://www.youtube.com/@X-Spaces',
    }
  ]

  return (
    <Flex
      sx={{
        '@media screen and (max-width: 820px)': {
          display: 'flex',
          flexDirection: 'column',
          bg: 'unset',
          border: 'none',
          w: '100%',
          p: '0 20px',
        }
      }}
    >
      <Center
        sx={{
          '@media screen and (max-width: 820px)': {
            mb: '20px'
          }
        }}
      >
        <ScrollLink
          to="about"
          smooth={true}
          duration={500}
          offset={-200}
          className='menu-item'
          onClick={handleMenuItemClick}
        >
          About
        </ScrollLink>
      </Center>
      <Center
        sx={{
          '@media screen and (max-width: 820px)': {
            mb: '20px'
          }
        }}
      >
        <ScrollLink
          to="features"
          smooth={true}
          duration={500}
          offset={-180}
          className='menu-item'
          onClick={handleMenuItemClick}
        >
          Features
        </ScrollLink>
      </Center>
      <Center
        sx={{
          '@media screen and (max-width: 820px)': {
            mb: '20px'
          }
        }}
      >
        <NavLink
          to="/"
          className='menu-item'
          onClick={handleClick}
        >
          Staking
        </NavLink>
      </Center>
      <Center
        sx={{
          '@media screen and (max-width: 820px)': {
            mb: '20px'
          }
        }}
      >
        <NavLink
          to="/"
          className='menu-item'
          onClick={handleClick}
        >
          Governance
        </NavLink>
      </Center>
      <Center
        sx={{
          '@media screen and (max-width: 820px)': {
            mb: '20px'
          }
        }}
      >
        <NavLink
          to="/"
          className='menu-item'
          onClick={handleDocsClick}
        >
          Docs
        </NavLink>
      </Center>
      <Center
        sx={{
          '@media screen and (max-width: 1000px)': {
            display: 'none'
          }
        }}
      >
        <NavLink
          to="/"
          className='menu-item'
        >
          <NavMenuList title="Communities" list={statsList} />
        </NavLink>
      </Center>
    </Flex>
  )
}